* {
    box-sizing: border-box;


}



/* The default font-size for larger screens */
html {
    font-size: clamp(12px, 1.5vw, 16px);
}
/* If screen size is 1200px or less, slightly decrease the font-size */
@media screen and (max-width: 1400px) {
    html {
        font-size: 16px;
    }
}

@media screen and (max-width: 1367px) {
    html {
        font-size: 16px;
    }
}

/*
@media screen and (max-height: 1050px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-height: 900px) {
  html {
    font-size: 14px;
  }
}
@media screen and (max-height: 845px) {
  html {
    font-size: 12px;
  }
}
@media screen and (max-height: 769px) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-height: 600px) {
  html {
    font-size: 11px;
  }
}




*/

:root {
    --color-shadow0: hsla(0, 0%, 0%, 0.75);
    --color-shadow1: hsla(0, 0%, 12%, 0.75);
    --color-shadow2: hsla(0, 0%, 18%, 0.75);
    --color-shadow3: hsla(0, 0%, 24%, 0.75);


    --color-dark-shadow: hsla(0, 0%, 0%, 0.5);
    --color-dark0: hsl(0, 0%, 12%);
    --color-dark1: hsl(0, 0%, 18%);
    --color-dark2: hsl(0, 0%, 25%);
    --color-dark3: hsl(0, 0%, 30%);

    --color-dark0-transparent: hsla(0, 0%, 12%, 0.5);
    --color-dark1-transparent: hsla(0, 0%, 18%, 0.5);
    --color-dark2-transparent: hsla(0, 0%, 25%, 0.5);
    --color-dark3-transparent: hsla(0, 0%, 30%, 0.5);

    --color-dark-gray-tint: hsl(220, 13%, 18%);
    --color-gray-tint: hsla(210, 8%, 34%, 0.8);
    --color-gray-tint1: hsla(207, 10%, 17%, 0.75);

    --color-middle-gray: hsl(0, 0%, 40%);



    --color-light0: hsl(0, 0%, 88%);
    --color-light1: hsl(0, 0%, 76%);
    --color-light2: hsl(0, 0%, 64%);
    --color-light3: hsl(0, 0%, 52%);
    --color-light-gray-tint: hsl(221, 18%, 61%);


    --color-text: hsl(0, 0%, 88%);
    --color-text-secondary: hsl(0, 0%, 76%);

    --color-warning: hsl(36, 100%, 50%);
    --color-warning-secondary: hsl(48, 100%, 50%);
    --color-warning-tertiary: hsl(40, 100%, 65%);

    --color-main-theme: hsl(211, 35%, 50%);
    --color-main-theme-light: hsl(211, 100%, 75%);
    --color-main-theme-light-transparent: hsla(211, 100%, 75%, 0.75);
    --color-main-theme-dark-desaturated: hsl(216, 25%, 33%);
    --color-main-theme-darker-desaturated: hsl(216, 25%, 17%);

    --color-main-theme-alpha: hsla(213, 76%, 45%, 0.25);

    --color-main-theme-dark: hsl(216, 45%, 34%);
    --color-main-theme-dark-transparent: hsla(216, 45%, 34%, 0.5);
    --color-main-theme-darker: hsl(216, 45%, 25%);
    --color-main-theme-darkest: hsl(224, 90%, 16%);
    --color-main-theme-darkest-transparent: hsla(224, 90%, 16%, 0.5);
    --color-main-theme-saturated: hsl(207, 100%, 50%);

    --color-error: hsla(0, 100%, 44%, 0.7);
    --color-error-dark: hsl(0, 100%, 44%);
    --color-success: hsl(120, 99%, 37%);
    --color-success-light: hsl(120, 100%, 44%);

    --background-gradient-a: linear-gradient(to bottom, var(--color-main-theme-dark), var(--color-main-theme-darker));
    --background-gradient-b: linear-gradient(to right, var(--color-dark-gray-tint), var(--color-dark0));
    --background-gradient-c: linear-gradient(to bottom, var(--color-main-theme-dark-desaturated), var(--color-main-theme-darker-desaturated));
    --background-gradient-d: linear-gradient(to bottom, var(--color-dark-gray-tint), var(--color-dark0) 125%);
    --background-gradient-e: linear-gradient(to bottom, rgba(49,80,125,0.8), hsla(224, 93%, 16%, 0.43));
    --background-gradient-glow: linear-gradient(to bottom, var(--color-main-theme-dark), var(--color-main-theme-saturated));

    --theme-clinic-color: hsl(118, 76%, 43%);
    --theme-medcenter-color: hsl(60, 100%, 50%);
    --theme-hospital-color: hsl(0, 100%, 50%);
    --theme-dentist-color: hsl(0, 0%, 100%);
    --theme-doctor-color: hsl(211, 100%, 75%);


    --button-hover-glow: hsl(211, 100%, 75%);
    --button-hover-glow-transparent1: hsla(211, 100%, 75%, 0.75);
    --button-hover-glow-transparent2: hsla(211, 100%, 75%, 0.5);
    --button-hover-glow-transparent3: hsla(211, 100%, 75%, 0.25);
    --button-hover-glow-gradient: linear-gradient(to bottom, hsla(215, 43%, 35%, 0.8), hsla(213, 100%, 63%, 0.78));




}





input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
}

input .MuiInputBase-input {
    background-color: transparent !important;
    color: #000000 !important;
}

input:auto-fill {
    background-color: transparent !important;
    color: #000000 !important;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
    color: #000000 !important;

}

*::-webkit-scrollbar {
    width: 8px;
    background: rgb(33, 33, 33);
}


*::-webkit-scrollbar-thumb {
    width: 8px;

    background: rgb(100, 100, 100);
    background: linear-gradient(270deg, rgb(55, 55, 55), rgb(100, 100, 100))
}

body {
    scrollbar-width: 5;
    background-color: #0e0e10;
    font-family: sans-serif;
    margin: 0;
    padding: 0;
    color: var(--color-text);
}

h1 {
    margin-top: 0;
    font-size: 22px;
}

h2 {
    margin-top: 0;
    font-size: 20px;
}

h3 {
    margin-top: 0;
    font-size: 18px;
}

h4 {
    margin-top: 0;
    font-size: 16px;
}

h5 {
    margin-top: 0;
    font-size: 14px;
}

h6 {
    margin-top: 0;
    font-size: 12px;
}

code {
    font-size: 1.2em;
}

ul,
ol {
    padding-left: 20px;
    font-weight: bolder;

}

li {
    padding-left: 20px;
    color: rgb(255, 255, 255);

}

.MuiSelect-select {
    padding: 6px 0px 6px 15px !important;

}


.status {
    margin-bottom: 10px;
}




div.accordClass {
    background-color: #a7aaab45;
    background-image: linear-gradient(to right, #ffffff0d, #00010952), linear-gradient(to bottom, #626465, #191c2187);
    color: #f3f3f3;
}

.accordClass:hover {
    background-color: #db0e073f;
    background-image: linear-gradient(to right, #ffffff0d, #00010952), linear-gradient(to bottom, #626465, #191c2187);
    color: #f3f3f3;
    padding: 10 10 10 10;

}

.accordText{
    color: #f3f3f3;
    font-size: 1.2rem !important;
    font-weight: bolder;
    padding: 0px 12px;
}
.accordSumClass{
    min-height: 40px !important;
}


@media screen and (max-height: 1200px) {
    .accordText{
        font-size: 1.1rem !important;
        font-weight: bolder;
        padding: 0px 8px;
    }

}
@media screen and (max-height: 900px) {
    .accordText{

        padding: 0px 6px;
    }

}





.AppBar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    margin: 0px;
    border-radius: 0px;
    height: 60px;
    width: 100vw;
    max-width: 100%;
    min-width: 100%;
    background: var(--background-gradient-a);
    z-index: 1000;

}

.CompLogoBackground{
    padding: 10px 20px;
    height: 100%;
    background: var(--background-gradient-d);
    display: flex;
    align-items: center;
}

@media screen and (max-height: 900px) {
    .AppBar{
    height: 45px;
}
    .CompLogoBackground{
        padding: 0px 20px;
    }
    .CompLogoBackground img {
        height: 30px !important;
    }
}


.BottomNavBar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    border-radius: 0px;
    max-width: 100%;
    min-width: 100%;
    z-index: 1000;
    width: 100%;
    height: 3rem;
    position: relative;
}

.BottomNavBarSpacer{
    height: 100%;
    flex-grow: 1;
    background: var(--background-gradient-a);
}

.activeRegionTab{
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}


.HospListBox {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
}

.HospSearchMain {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.patient-container {
    flex-grow: 1;
    overflow: auto;
    background-color: rgba(34, 34, 37, 0.9);
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}



.right {
    width: 100%;
    height: 100%;
    flex: 1;
    justify-content: flex-end;
    align-self: flex-start;

}


@media screen and (max-width: 1200px) {
    .patient-container {
        display: block;
        flex-direction: column;
        height: 2000px !important;

    }

    .right {
        height: 1000px;
    }

    .left {
        width: 100% !important;
        max-width: 100%;
    }
}



.left {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 720px;
}

.MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
    background-color: #282d35;
    color: rgb(255, 255, 255);
}

.MuiAutocomplete-listbox {
    background: var(--background-gradient-b) !important;
}

.DataDrawer {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom,
            #243a5c 0%, #243a5c4a 90%);


}


.gradientTextspan {

    background: linear-gradient(to bottom, rgb(125, 188, 255), #2b395b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


}


/*
@media screen and (max-width: 1366px) {


  .patient-container.css-1p39ypq-MuiGrid-root {
    padding: 4px;
    display: flex;
    justify-content: center;

    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;

  }


  .form-container {
    width: 100%;
  }



  .left.css-5r66ez-MuiGrid-root {
    width: 100%;
    max-width: 100%;
  }
}

*/

#submitform {
    display: block;
    width: 100%;
    padding: 12px;
    margin: 0 auto;
    background-color: #0066cc;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#submitform:hover {
    background-color: #004499;
}

.toggleButGroup {
    display: flex !important;
}



.autocomp {
    display: flex;
    width: 100%;
    padding: 12px;
    margin-bottom: 0px;
    font-size: 16px;
    border-width: 2px;
    background-color: #575454;
    transition: border-color 0.2s ease;
    color: rgb(253, 253, 253);
    border-bottom-width: 6px;
}

.MuiAutocomplete-noOptions {
    background-color: rgb(48, 48, 48);
    color: whitesmoke;
}

.searchBox {
    background-color: rgb(48, 48, 48);
    color: rgb(253, 253, 253);
}




.formTextField.MuiTextField-root {
    color: #148fcc;
    width: 100%;
    border-radius: 12px;
    font-size: 16px;
    background-color: #575454;
    transition: border-color 0.2s ease;
}

input {
    color: var(--color-text);
    display: block;
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid var(--color-light0);
    border-radius: 6px;
    font-size: 16px;
    border-width: 2px;
    background-color: var(--color-dark3);
    transition: border-color 0.2s ease;
}

.MuiFormLabel-root {
    color: var(--color-text);
}



/* Disabled styles */
input:disabled {
    background-color: #f8f8f8;
    color: #999;
    cursor: not-allowed;
}

/* Error styles */
input.error {
    background-color: #ffebee;
    color: #09ff00;
}

/* Success styles */
input.success {
    background-color: #e8f5e9;
    color: #2e7d32;
}

/* Placeholder styles */
input::placeholder {
    color: #999;
}

/* Label styles */
label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    margin-right: 1rem;
    font-weight: bold;
}

/* Error message styles */
.error-message {
    color: #b71c1c;
    font-size: 14px;
    margin-top: 5px;
}

/* Success message styles */
.success-message {
    color: #2e7d32;
    font-size: 14px;
    margin-top: 5px;
}



.mapboxgl-popup {
    padding: 0px;

}


.mappopupHotel {
    background-color: rgba(63, 63, 68, 0.6);
    position: relative;
    backdrop-filter: blur(2px);
    border-radius: 18px;
    box-shadow: 0 0 4px 4px rgba(2, 175, 255, 0.4);

    width: fit-content;
    pointer-events: auto;
}

.mappopupHotel .mapboxgl-popup-content h1 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding: 4px;
    padding-bottom: 4px;
    padding-right: 4px;
    margin: 0px;

}

.mappopupHotel .mapboxgl-popup-content {
    padding: 6px;

    padding-top: 8px;
    align-self: center;
    max-width: 200px;
}



.mappopupHosp {
    background-color: rgba(63, 63, 68, 0.6);
    position: relative;
    backdrop-filter: blur(2px);
    border-radius: 18px;
    box-shadow: 0 0 4px 4px rgba(255, 2, 2, 0.4);

    width: fit-content;
    pointer-events: auto;
}

.mappopupHosp .mapboxgl-popup-content h1 {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding: 4px;
    padding-bottom: 4px;
    padding-right: 4px;
    margin: 0px;

}

.mappopupHosp .mapboxgl-popup-content {
    padding: 6px;

    padding-top: 8px;
    align-self: center;
    max-width: 200px;
}










.mappopup {
    background-color: rgba(12, 23, 34, 0.849);
    position: relative;
    border-radius: 18px;
    box-shadow: 0 2px 12px 2px rgba(255, 0, 0, 0.804);
    pointer-events: auto;
}

.mapboxgl-popup-content {
    background: #00000000;
    box-shadow: none;
    border-radius: 8px;
}

.mapboxgl-popup-content h1 {
    color: rgba(255, 252, 252, 1);
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: #00000000;
    position: absolute;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: #00000000;
    position: absolute;
}




.popup-Button {
    width: 100%;
    height: 100%;
    border: 0;
    background: transparent;
    color: white;
    font-size: 1.15rem;
    font-weight: bold;
}

.custom-marker-markhosp {
    width: 100%;
    height: 100%;
}


.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.MuiSelect-select {
    /*
find a better way to target language selector!!!
*/
    display: flex;
}

.minimapElementContainer {
    height: 55;
    width: 150;
    position: absolute;
    display: block;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

/*
svg text:not(.pieCenterText)  {
  font-size: 1.25rem !important;
}
*/




.DisplayDataMain {
    background: rgb(66, 66, 66);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.ChartContainer {

    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
}



.ResponsivePieArc .pieCenterText {
    font-size: 2rem !important;
}



.ResponsivePieArc .pieCenterPercent {
    font-size: 1.25rem !important;
}

.ResponsivePieArc svg text {
    font-size: 1.2rem !important;
}

.LoginPageContainer {
    background: linear-gradient(to top, rgb(37 38 40) 0%, rgb(43 45 49) 100%);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
}


.LoginBox {
    position: relative;
    width: 50%;
    max-height: 60%;
    border-radius: 12px;
    border: 1px solid rgba(125, 188, 255, 0.05);
    background: rgba(11, 18, 30, 0.25);
    padding-top: 3rem;
    box-shadow: 1px 6px 40px 16px rgba(26, 26, 26, 0.4);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    gap: 2.5rem;
    min-height: 400px;
    max-width: 1000px;
    min-width: 400px;
}

.LoginTopContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    width: 100%;
    padding-inline: clamp(1rem, 8%, 8rem);

}

.LoginLogoContainer{

        position: absolute;
        padding: 4px;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        bottom: 16px;
        right: 16px;

}
.searchDrawer {
    z-index: 100;
    transition: height 0.3s ease;
    background: rgba(37, 37, 38, 0.65);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border-radius: 0px 0px 16px 16px;

    border-top: none;
    color: white;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;

}

.searchDrawerOpen {
    height: 200px; /* Adjust this height as needed */
}

.searchDrawerContainer{
    border-bottom: 2px solid var(--color-main-theme-dark-transparent);
    border-left: 2px solid var(--color-main-theme-dark-transparent);
    border-right: 2px solid var(--color-main-theme-dark-transparent);
    border-top: 2px solid var(--color-dark-gray-tint);
    border-radius: 0px 0px 8px 8px;
    overflow-y: auto;
    max-height: 250px;
    box-shadow: 0px 12px 16px 2px rgba(0, 0, 0, 0.35);
}

.PolicyValidTextContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    gap: 1rem;
    background: rgba(190, 190, 190, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.35);
}


.HotelEditFormContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: rgba(36, 38, 43, 0.5);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    overflow-y: auto;

}

.CreatePatientFormWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    background: #1c1c1c;
    overflow-y: auto;
}
.PatientCaseInfoCreateForm{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background: #1c1c1c;
    box-shadow: inset 0px 0px 16px 4px rgba(0, 0, 0, 0.3);
}

.HotelEditFormGroup {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 2rem;
    overflow: auto;
    background: #1c1c1c;
}


.HotelEditFormTitle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 68px;
    height: 68px;
    padding: 16px;
    background: var(--background-gradient-e);
}
.HotelEditFormButton{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    background: transparent;
    color: white;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding-inline: 1rem;
}

.top-search-container {
    display: flex;
    flex-direction: row;
    background: linear-gradient(to top, rgb(50, 58, 87) 0%, rgba(125, 188, 255, 0.5) 80%);
    align-items: center;
    padding-inline: 1rem;
    gap: 1.5rem;
}

.RegionDisplayBox {
    display: flex;
    flex-direction: row;
    background: linear-gradient(to bottom, rgb(43, 44, 46) 0%, rgba(39, 40, 41, 0.724) 80%);
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 12px;
    margin: 0px 10px;
    padding-inline: 1.5rem;
    box-shadow: inset 0px 0px 24px 8px #29292966;
}


.form-row {
    gap: 2.5rem;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding-block: 0.5rem;

}

.PatientFormRow{
    justify-content: flex-start;
    width: 100%;
    display: flex;
    @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }
}

.DataEditorTableContainer {
    flex-grow: 1;
    overflow: auto;
    background-color: transparent;
    padding: 0;
    margin: 0;
    border-bottom: 16px solid rgb(40, 49, 58);
}




.selectedClass {
    background: linear-gradient(0deg, rgb(26 26 26) -21%, rgb(69 69 69) 100%);

}


.ColumnBody {
    padding: 0px;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
    background:linear-gradient(0deg, rgb(1 103 251) -27%, rgb(35 59 94) 50%, rgb(49,80,125) 125%) !important;
    color: white !important;
}


#divTable {
    display: flex !important;
    justify-content: flex-start !important;
    align-content: flex-start !important;
    flex-wrap: nowrap !important;
    flex-direction: column !important;
    align-items: stretch !important;
}

.CustomFilterContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    position: relative !important;

}

.p-selection-column {
    justify-content: center !important;
    align-items: center !important;
}

.p-selection-column .p-checkbox {
    color: rgb(245, 245, 245);
    background: rgb(9, 255, 0);
}

.p-column-title {
    width: 100%;
}

.p-contextmenu ul {
    padding: 0px !important;
    margin: 0px !important;
}

.p-contextmenu li {
    padding: 0px !important;
    margin: 0px !important;
}






.GeocodeSelectionContainer {
    background: transparent;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1000;
    color: white;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    margin: 0px;
    border-radius: 12px 12px 0px 0px;

    z-index: 1000;
    width: auto;
    max-width: 20rem;

    background: rgb(37, 37, 37);
    border: #494949 2px solid;
}

.RegionSelectorContainer {
    border: 2px solid var(--color-dark3);
    background: rgb(41, 41, 41);
    border-radius: 12px;

    display: flex;
    flex-direction: column;

    flex-wrap: wrap;
    align-items: flex-start;
    align-self: flex-start;
    width: 100%;
    box-shadow: 0px 0px 24px 8px #121212ab;
}
.RegionSelectorContainerDialog {
    border: 2px solid var(--color-dark3);
    background: rgb(41, 41, 41);
    border-radius: 12px;
    padding: 1.25rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;

    flex-wrap: wrap;
    align-items: flex-start;
    align-self: flex-start;
    width: 100%;
    box-shadow: 0px 0px 24px 8px #121212ab;
}

.RegionSelectorFieldRow{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    min-width: 300px;
}
.RegionSelectorContainerForm{

        display: flex;
        flex-direction: column;
        gap: 1rem;
        background: rgb(29, 32, 37);
        border: 1px solid rgba(49, 79, 125, 0.3);
        box-shadow: 0px 0px 24px 2px rgba(11, 11, 11, 0.45);
        padding: 2rem;
        border-radius: 16px;
        width: 100%;

}

.RegionTopWrapper {
    background: rgb(37, 37, 37);
    width: 100%;
    padding: 8px 16px;
    border-radius: 12px 12px 0px 0px;
    border-bottom: 4px solid #585858;
}
.RegionBottomWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    background: rgb(53, 56, 59);
    border-radius: 0px 0px 12px 12px;
    width: 100%;
    box-shadow: inset 0px 6px 13px -6px rgba(0, 0, 0, 0.57);
}

.RegionSelector {
    display: flex;
    flex-direction: row;

    min-width: 300px;
    max-width: 500px;

}

.RegionChipContainer {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
    align-items: flex-start;
    align-self: flex-start;
    padding: 0.75rem;
    background: rgb(45 45 45);
    border: 2px solid rgb(99, 99, 99);
    border-radius: 12px;


}

.CreateEntryFormContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1.5rem;
    padding-top: 2rem;
    width: 100%;
    height: 100%;
    max-width: 45rem;
    min-width: 25rem;
    background: rgb(26, 26, 26);
    overflow-y: auto;

}


.ContactModalContainer{
    display: flex;
    flex-direction: column;
    gap: 4.5rem;
    align-items: flex-start;
    padding: 4.5rem;
    padding-top: 2rem;
    width: 100%;
    height: 100%;

    background: rgba(26, 26, 26, 0.681);
    overflow-y: auto;
    border-radius: 12px;
    border: 2px solid rgb(99, 99, 99);
    box-shadow: 0px 0px 24px 8px #1212124a;

}
.ContactModalTitleContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.35rem;
    align-items:center;
}
.ContactModalInfoContainer{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}


.HospitalEditFormFieldTopGroup{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 3rem;
}

